<template>
  <b-container>
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h3 style="margin-top: 4.25rem;">{{$store.state.bike.name}}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="color: var(--secondary-text-icon-color)">Akkustand: {{$store.state.bike.power}}%</p> 
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <h4>Bewertung</h4>
        <p>Bitte bewerte das Fahrrad, damit wir den aktuellen Stand haben und es im Notfall reparieren können.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="white-space: pre-line;">{{ message }}</p>
        <br>
        <b-input class="textarea" v-model="message" placeholder="Bewertung hier eingeben"/> 
      </b-col> 
      <b-col cols="1"/>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'BikeUnlockRating',
  data() {
    return {
    }
  },
  mounted() {
    // checks if the page loaded without any bike data
    if (Object.keys(this.$store.state.bike).length === 0) {
      this.$router.push('/').then(()=> this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger'));
    }
  }
}
</script>

<style scoped>
.textarea {
  border-radius: 13rem;
  width: 18rem;
  color: var(--secondary-text-icon-color);
  border-color: var(--secondary-text-icon-color);
}

</style>